import { find, findAll } from "../utils/dom";

const Company = () => {

    const ButtonLimitText = findAll("[js-button-limit-box]");
    const BoxText = find("[js-limit-box]");

    if (ButtonLimitText) {
        ButtonLimitText.forEach((button) => {
            button.addEventListener("click", (e) => {
                BoxText.classList.toggle("is_open");

                if (BoxText.classList.contains("is_open")) {
                    if (e.target.attributes["css-leng"].value == "pt-br") {
                        e.target.innerText = "Ver Menos";
                    }
                    else {
                        e.target.innerText = "Read Less";
                    }
                } else {
                    if (e.target.attributes["css-leng"].value == "pt-br") {
                        e.target.innerText = "Ver Mais";
                    }
                    else {
                        e.target.innerText = "Read More";
                    }
                }
            })
        })
    }
}

export default Company;