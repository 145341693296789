import Swiper from "swiper";
import '@splidejs/splide/css';
import Splide from '@splidejs/splide';
import { findAll } from "../utils/dom";

const Home_Projects = () => {
    const SwiperProject = new Swiper(".swiper-project", {
        slidesPerView: 1,
        spaceBetween: 10,
        slideToClickedSlide: true,
        speed: 500,
        //cssMode: true,
        //touchRatio: 1,
        //touchStartPreventDefault: false,
        //allowTouchMove: true,
        pagination: {
            el: ".swiper-project .swiper-pagination.externo",
            type: 'bullets',
            clickable: true,
        },
        mousewheel: {
            forceToAxis: true,
        },
        /*navigation: {
            nextEl: ".swiper-project .swiper-button-next",
            prevEl: ".swiper-project .swiper-button-prev",
        },*/
        breakpoints: {
            600: {
                spaceBetween: 20,
                slidesPerView: 2.2,
                cssMode: false,
                speed: 600,
                pagination: {
                    el: ".swiper-project .swiper-pagination",
                    type: 'bullets',
                    clickable: true,
                },
            },
            767: {
                cssMode: false,
                pagination: {
                    el: ".swiper-project .swiper-pagination",
                    type: "progressbar",
                },
            },
            1024: {
                cssMode: false,
                spaceBetween: 26,
                slidesPerView: 3.2,
                pagination: {
                    el: ".swiper-project .swiper-pagination",
                    type: "progressbar",
                },
            },
            1600: {
                cssMode: false,
                spaceBetween: 26,
                slidesPerView: 4,
                pagination: {
                    el: ".swiper-project .swiper-pagination",
                    type: "progressbar",
                },
            },
        },
    });

    /*document.querySelector(".swiper-project .swiper-wrapper").addEventListener("touchmove", (e) => {
        console.log("entrou")
    })*/
    SwiperProject.on("slideChange", function (e) {
        const wrapper = document.querySelector(".swiper-project .swiper-wrapper");
        //console.log(e);
        wrapper.click();

    })


    /* const project_item = document.querySelector(".swiper-project-item");
     project_item.owlCarousel({
         loop: true,
         margin: 10,
         responsiveClass: true,
     })*/

    /*$('.owl-carousel').owlCarousel({
        loop: false,
        margin: 10,
        items: 1,
        responsiveClass: true,
        nav: true,
        dots: true,

    })*/

    const AllSplide = findAll(".splide-project-item");
    if (AllSplide) {
        AllSplide.forEach((item) => {
            new Splide(item, {
                drag: false,
            }).mount();
        })
    }

    /* new Splide('.splide').mount();*/

    /*const project_item = document.querySelectorAll(".swiper-project-item");
    project_item.forEach((item) => {
        const SwiperProjectItem = new Swiper(item, {
            effect: 'fade',
            speed: 500,
            crossFade: true,
            //touchRatio: 0,
            navigation: {
                nextEl: ".swiper-project-item .swiper-button-next.slide-interno",
                prevEl: ".swiper-project-item .swiper-button-prev.slide-interno",
            },
            pagination: {
                el: ".swiper-project-item .swiper-pagination",
            },
        });
    })*/
}

export default Home_Projects;