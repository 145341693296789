import { find, findAll } from "../utils/dom";


const home_plataforms = () => {
    const AllLabel = findAll(`[js-plataform='label']`);


    function removeLabel() {
        const Label = find(`[js-plataform='label'].is_expand`);
        if (Label) {
            //controlVideo()
            Label.classList.remove('is_expand');
        }
    }

    function ScrollTarget(idPlataform) {
        setTimeout(() => {
            window.location.href = `#${idPlataform}`;
        }, 500)
    }

    /** Função para realizar uma operação no vídeo do youtube */
    /** Precisa ser revisto, pois não tá funcionando. */








    fetch("https://www.youtube.com/iframe_api").then((e) => { console.log("erro") }).catch((e) => {
        let player1;
        function onYouTubeIframeAPIReady() {

            setTimeout(() => {
                player1 = new YT.Player('video-platform', {
                    height: '200',
                    width: '520',
                    videoId: 'DezVxQ_lZiE',
                    events: {
                        'onStateChange': onPlayerStateChange
                    }
                });
            }, 400);

        }

        function onPlayerStateChange(event) {
            if (event.data == YT.PlayerState.PLAYING) {
                pauseVideo(event.target.a.id);
            }
        }

        function pauseVideo(player_id) {
            player1.pauseVideo();

        }

        onYouTubeIframeAPIReady();


        if (AllLabel) {
            AllLabel.forEach((item) => {
                item.addEventListener("click", (e) => {
                    const IdPlataform = e.target.attributes['id'].value;

                    if (e.target.classList.contains('is_expand')) {
                        // controlVideo();
                        e.target.classList.remove('is_expand');

                    }
                    else {
                        removeLabel();
                        pauseVideo();
                        e.target.classList.add('is_expand');

                        if (window.matchMedia("(min-width: 1024px)").matches) {
                            ScrollTarget(IdPlataform);
                        }

                    }

                })
            })
        }


        const ButtonClosed = findAll("[js-button-closed='plataform']");
        ButtonClosed.forEach((item) => {
            item.addEventListener("click", (e) => {

                pauseVideo();
                const ActivePlataform = find("[js-plataform='label'].is_expand");
                removeLabel();
                ActivePlataform.classList.remove('is_expand');
            })
        })

    })

}

export default home_plataforms;