import { findAll, find } from "../utils/dom";

const Debug = () => {


    function Reset_Page() {

        const page_current_leng = findAll(".glink");

        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                const { target } = mutation;

                if (mutation.attributeName === 'class') {
                    const currentState = mutation.target.classList.contains('gt-current-lang');
                    if (currentState) {
                        find("html").style.opacity = 0;
                        setTimeout(() => {
                            window.location.href = "/";
                        }, 1600)

                    }
                }
            })
        });

        if (page_current_leng) {
            page_current_leng.forEach((item) => {
                observer.observe(item, { attributeFilter: ['style', 'class'] })
            })
        }




        /*setTimeout(() => {
            const page_current_leng = findAll(".glink");
            if (page_current_leng) {

                console.log(page_current_leng)

                page_current_leng.forEach((item) => {
                    item.addEventListener("click", (link) => {
                        find("html").style.opacity = 0;
                        setTimeout(() => {
                            window.location.href = "/";
                        }, 300)

                    })
                })


               

            }
        }, 3000)*/



    }

    document.addEventListener("DOMContentLoaded", () => {
        Reset_Page();
    })

}

export default Debug;