import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageLogin from "./pages/Login";
import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from 'swiper/element/bundle';
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import formErros from "./modules/formErros";
import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";

import Home_Nav from './sections/home_nav';
// import SvgUse from "./utils/SvgUse";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import PageDisclaimer from './pages/Disclaimer.js';

import Debug from './modules/debug';
import Copiar_Colar from './modules/componentes';

import Contact from "./modules/contact";


/*import config from './utils/config';

config();*/

// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
animaAoScroll()

// Pages
PageHome()
PageDisclaimer()
PageLogin()?.init()

// Sections
Home_Nav();

// utils logs
logs()

Debug()

Contact();

Fancybox.bind("[data-fancybox]", {
    // Your custom options
});

document.addEventListener("DOMContentLoaded", () => {
    Copiar_Colar();
})


