import { find } from "../utils/dom";

export default function () {
    const Disclaimer = find("[js-page='disclaimer']")
    if (!Disclaimer) return


    /* Ativa o 'fechar' no menu hamburgue */
    document.body.classList.add("MenuPrincipalOpen");

}