import Swiper from "swiper";
import animateFrom from "../animation/animateFrom";
import { find } from "../utils/dom";
import { CountUp } from "countup.js";

const Home_Header = () => {



    const Swiper_Home = new Swiper(".swiper-home", {
        slidesPerView: 1,
        autoplay: {
            delay: 5000,
        },
        speed: 1000,
        effect: 'fade',
        pagination: {
            el: ".swiper-home .swiper-pagination",
            type: "progressbar",
        },
        navigation: {
            nextEl: ".swiper-home .swiper-button-next",
            prevEl: ".swiper-home .swiper-button-prev",
        },
    });


    const NumberToCount = document.querySelectorAll("[js-number]");
    if (NumberToCount) {
        NumberToCount.forEach((e) => {
            let number = e.innerText;

            let countUp = new CountUp(e, number);

            setTimeout(() => {
                countUp.start();
            }, 300)

        })
    }

}

export default Home_Header;