import { find, findAll, width } from "../utils/dom";

const Home_Nav = () => {

    const Menu_Principal = find("[js-menu-principal]");
    const Menu_Login = find("[js-menu-login]");

    const SubMenu = findAll("[js-sub-menu");
    const ButtonClosedSubMenu = findAll("[closed-sub-menu]")

    const Nav_Button = find("[js-button-menu-principal]");
    const Login_Button = find("[js-login-button]");

    const Links_Menu = findAll("[js-link-menu]");


    const Closed_Menu_Principal = () => {
        Menu_Principal.classList.remove("is_open");
        document.body.classList.remove("MenuPrincipalOpen");
    }
    const Open_Menu_Principal = () => {
        Menu_Principal.classList.add("is_open");
        document.body.classList.add("MenuPrincipalOpen");
    }
    const Is_Active_Menu_Principal = () => {
        if (Menu_Principal.classList.contains("is_open")) {
            return true;
        }
        return false;
    }

    const Closed_Menu_Login = () => {
        Menu_Login.classList.remove("is_open");
        document.body.classList.remove("MenuLoginOpen");
    }
    const Open_Menu_Login = () => {
        Menu_Login.classList.add("is_open");
        document.body.classList.add("MenuLoginOpen");
    }
    const Is_Active_Menu_Login = () => {
        if (Menu_Login.classList.contains("is_open")) {
            return true;
        }
        return false;
    }

    function removeSubMenu(idSubMenu) {
        const SubMenu = findAll(`[js-sub-menu='${idSubMenu}']`)
        if (SubMenu) {
            SubMenu.forEach((item) => {
                item.classList.remove("is_expand");
            })
        }
    }


    function removeContentSubMenu(idContentMenu) {
        const SubMenuContent = findAll(`[js-sub-menu-content='${idContentMenu}']`);
        if (SubMenuContent) {
            SubMenuContent.forEach((item) => {
                item.classList.remove("is_expand");
            })
        }
    }
    function activeContentSubMenu(idContentMenu) {
        const SubMenuContent = findAll(`[js-sub-menu-content='${idContentMenu}']`);
        if (SubMenuContent) {
            SubMenuContent.forEach((item) => {
                item.classList.add("is_expand");
            })
        }
    }

    if (Nav_Button) {
        Nav_Button.addEventListener("click", () => {
            if (Is_Active_Menu_Login()) {
                Closed_Menu_Login();
            }
            else if (Is_Active_Menu_Principal()) {
                Closed_Menu_Principal();
            }
            else {
                Closed_Menu_Login();
                Open_Menu_Principal();
            }
        })
    }

    function activeButtonSubMenu(idButton) {
        const button = findAll(`[closed-sub-menu="${idButton}"]`);
        if (button) {
            button.forEach((item) => {
                item.classList.add("is_active");
            })
        }
    }
    function removeButtonSubMenu(idButton) {
        const button = findAll(`[closed-sub-menu='${idButton}']`);
        if (button) {
            button.forEach((item) => {
                item.classList.remove("is_active");
            })

        }
    }

    if (ButtonClosedSubMenu) {
        ButtonClosedSubMenu.forEach((item) => {
            item.addEventListener("click", (e) => {
                const idContentSubMenu = e.target.attributes['closed-sub-menu'].value;
                removeContentSubMenu(idContentSubMenu);
                removeButtonSubMenu(idContentSubMenu);
                removeSubMenu(idContentSubMenu);
            })
        })
    }

    if (Login_Button) {
        Login_Button.addEventListener("click", () => {

            if (!Is_Active_Menu_Login()) {
                Closed_Menu_Principal();
                Open_Menu_Login();
            }

        })
    }


    if (SubMenu) {
        SubMenu.forEach((item) => {
            item.addEventListener("click", (e) => {
                const idContentMenu = e.target.attributes['js-sub-menu'].value;

                if (idContentMenu) {
                    if (e.target.classList.contains("is_expand")) {
                        removeContentSubMenu(idContentMenu)
                        removeButtonSubMenu(idContentMenu);
                        e.target.classList.remove("is_expand");
                    } else {
                        activeContentSubMenu(idContentMenu);
                        e.target.classList.add("is_expand");
                        activeButtonSubMenu(idContentMenu);
                    }
                }


            })
        })
    }

    if (Links_Menu) {
        Links_Menu.forEach((item) => {

            const SubMenu = item.nextElementSibling;
            Closed_Menu_Principal();
            if (SubMenu) {

                item.addEventListener("click", (e) => {
                    e.preventDefault();

                    if (window.matchMedia("(max-width: 767px)").matches) {
                        let idValue = e.target.offsetParent.attributes['js-sub-menu'].value;
                        e.target.offsetParent.classList.add("is_expand");
                        activeButtonSubMenu(idValue);
                        // console.log(idValue)
                        activeContentSubMenu(idValue);
                    }

                })
            }
            else {
                item.addEventListener("click", (e) => {
                    // e.preventDefault();
                    Closed_Menu_Principal();

                })
            }


        })
    }


    document.addEventListener("scroll", () => {
        if (window.scrollY > 100) {
            document.body.classList.add("is_scroll");
        }
        else {
            document.body.classList.remove("is_scroll");
        }
    })

}

export default Home_Nav;