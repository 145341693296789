import { find } from "../utils/dom";

const what_makes_us_unique = () => {
    const AllItens = document.querySelectorAll('[js-list-item]');


    /** Função para remover a classe active do item da lista */
    const RemoveActive = () => {
        const ItemActive = find('[js-list-item].active');
        if (ItemActive)
            ItemActive.classList.remove("active");
    }

    const CheckActive = () => {
        return find('[js-list-item].active');
    }


    //Função para adicionar contéudo do item
    const AddContentItemMobile = (idItem) => {
        const ContentCurrent = document.querySelectorAll(`[js-list-content='${idItem}']`);
        if (ContentCurrent) {
            ContentCurrent.forEach((item) => {
                item.classList.add("max-h-[1600px]");
                item.classList.remove("max-h-[0px]");
                item.classList.remove("opacity-[0]");
                item.classList.add("opacity-[1]");
                item.classList.add("is_expand");
            })
        }
    }

    //Função para remover contéudo do item.
    const RemoveContentItemMobile = (idItem) => {
        const ContentCurrent = document.querySelectorAll(`[js-list-content='${idItem}']`);
        if (ContentCurrent) {
            ContentCurrent.forEach((item) => {
                item.classList.remove("max-h-[1600px]");
                item.classList.add("max-h-[0px]");
                item.classList.add("opacity-[0]");
                item.classList.remove("opacity-[1]");
                item.classList.remove("is_expand");
            })
        }
    }



    /** Função para trocar o contéudo do item */
    const SwapContentItem = (idItem) => {
        const ItemActiveAnt = find('[js-list-item].active')
        const ValueItemAnt = ItemActiveAnt.attributes['js-list-item'].value;

        RemoveContentItemMobile(ValueItemAnt);

        AddContentItemMobile(idItem);



        /*const ContentAnt = document.querySelectorAll(`[js-list-content='${ValueItemAnt}']`);
        if (ContentAnt) {
            ContentAnt.forEach((item) => {
                item.classList.add("opacity-[0]");
                item.classList.add("max-h-[0px]");
                item.classList.remove("max-h-[1600px]");
                item.classList.remove("opacity-[1]");
                item.classList.remove("is_expand");
            })
        }*


        const ContentCurrent = document.querySelectorAll(`[js-list-content='${idItem}']`);
        if (ContentCurrent) {
            ContentCurrent.forEach((item) => {
                item.classList.add("max-h-[1600px]");
                item.classList.remove("max-h-[0px]");
                item.classList.remove("opacity-[0]");
                item.classList.add("opacity-[1]");
                item.classList.add("is_expand");
            })
        }*/
    }

    const SwapContentItemMobile = () => {
        if (CheckActive()) {
            const ItemActiveAnt = find('[js-list-item].active')
            const ValueItemAnt = ItemActiveAnt.attributes['js-list-item'].value;

            RemoveContentItemMobile(ValueItemAnt);

            /* const ContentAnt = document.querySelectorAll(`[js-list-content='${ValueItemAnt}']`);
             if (ContentAnt) {
                 ContentAnt.forEach((item) => {
                     item.classList.add("opacity-[0]");
                     item.classList.add("max-h-[0px]");
                     item.classList.remove("max-h-[1600px]");
                     item.classList.remove("opacity-[1]");
                     item.classList.remove("is_expand");
                 })
             }*/

        }

    }


    if (AllItens) {
        AllItens.forEach((item) => {
            item.addEventListener("click", (e) => {

                if (window.screen.width > 1024) {
                    SwapContentItem(e.target.attributes['js-list-item'].value);
                    RemoveActive();

                    e.target.classList.add("active");
                }
                else {
                    if (!e.target.classList.contains("active")) {
                        SwapContentItemMobile();
                        RemoveActive();

                        AddContentItemMobile(e.target.attributes['js-list-item'].value);

                        e.target.classList.add("active");
                    }
                    else {
                        RemoveActive();
                        RemoveContentItemMobile(e.target.attributes['js-list-item'].value);
                        e.target.classList.remove("active");
                    }
                }
            });
        })
    }


    let ActiveFirstElement = false;
    window.addEventListener("resize", () => {
        if (window.screen.width > 1024 && ActiveFirstElement) {
            AddContentItemMobile(1);
            const ItemActive = find("[js-list-item='1']");
            ItemActive.classList.add("active");
            ActiveFirstElement = false;
        }
        else {
            ActiveFirstElement = true;
        }
    })
}

export default what_makes_us_unique;