import { find, findAll } from "../utils/dom";


const Home_Map = () => {

    /**
     * 
     * @param {Number} idStateTarget
     * @param {Object} e
     */
    function Add_Box_Info(idStateTarget, e) {


        const ContentState = findAll(`[js-content-state="${idStateTarget}"]`);
        const LaguraTela = window.matchMedia("(max-width: 767px)");
        if (ContentState) {
            ContentState.forEach((item) => {
                item.classList.add("active");
                /* ContentState.classList.remove('invisible');
                ContentState.classList.add('visible')
    
    
                ContentState.classList.remove('opacity-[0]');
                ContentState.classList.add('opacity-[1]');
                */
                if (LaguraTela.matches) {
                    item.style.top = `${e.y}px`;
                    item.style.left = `${e.x - 20}px`;
                }
                else {
                    item.style.top = `${e.y + 40}px`;
                    item.style.left = `${e.x - 120}px`;
                }
            })




        }
    }

    /**
     * 
     * @param {Number} idStateTarget 
     */
    function Remove_Box_Info(idStateTarget) {
        const ContentState = findAll(`[js-content-state="${idStateTarget}"]`);
        if (ContentState) {

            ContentState.forEach((item) => {
                item.classList.remove("active");
            })

            /*
            ContentState.classList.remove('visible');
            ContentState.classList.add('invisible')

            ContentState.classList.remove('opacity-[1]');
            ContentState.classList.add('opacity-[0]');
            */
        }
    }


    /**
     * 
     * @param {Object} item 
     */
    function add_Event_Mouse(item) {
        // Evento mouse por cima
        item.addEventListener("mouseover", (e) => {

            const StateTarget = e.target.attributes['js-state'].value;
            const SingleState = find(`[js-sigle-state="${StateTarget}"]`);
            SingleState.classList.add("is_active");

            // const rect = e.target.getBoundingClientRect();

            Add_Box_Info(StateTarget, e);


        })

        // Evento tirando o mouse por cima
        item.addEventListener("mouseout", (e) => {

            const StateTarget = e.target.attributes['js-state'].value;
            const SingleState = find(`[js-sigle-state="${StateTarget}"]`);
            SingleState.classList.remove("is_active");

            Remove_Box_Info(StateTarget);

        })
    }

    function Reset_Mobile_Config() {
        const StateCurrentMobile = find("[js-state].is_mobile_active");
        if (StateCurrentMobile) {
            StateCurrentMobile.classList.remove("is_mobile_active");
        }
    }


    function Change_Box_Info(StateTarget, e) {
        const SingleStateAnt = findAll(`[js-sigle-state].is_active`);
        if (SingleStateAnt) {

            SingleStateAnt.forEach((item) => {
                const StateAnt = findAll(`[js-state='${item.attributes['js-sigle-state'].value}']`)
                StateAnt.forEach((item2) => {
                    item2.classList.remove("is_mobile_active");
                })

                item.classList.remove("is_active");
                Remove_Box_Info(item.attributes['js-sigle-state'].value);
            })








        }

        const SingleState = findAll(`[js-sigle-state="${StateTarget}"]`);
        const StateCurrent = findAll(`[js-state='${StateTarget}']`);
        //console.log(`${SingleState}  -   ${StateCurrent}`);

        SingleState.forEach((item) => {
            item.classList.add("is_active");
        })

        StateCurrent.forEach((item) => {
            item.classList.add("is_mobile_active");
        })

        //SingleState.classList.add("is_active");
        //StateCurrent.classList.add("is_mobile_active");
        Add_Box_Info(StateTarget, e);


    }

    /**
     * 
     * @param {Object} item 
     */
    function add_Event_Click(item) {
        item.addEventListener("click", (e) => {

            //MapSVG.style.width = '1600px';
            /*setTimeout(() => {
                ContainerMap.scrollTo({
                    top: e.scrollTop,
                    left: e.scrollLeft,
                    behavior: "smooth",
                });
            }, 100)
            */

            const StateTarget = e.target.attributes['js-state'].value;

            Change_Box_Info(StateTarget, e);
        })
    }


    function add_Events(AllActiveMap, AllActiveMobileMap, Max_Width) {
        if (Max_Width.matches) {
            AllActiveMap.forEach((item) => {
                Reset_Mobile_Config();
                item.removeEventListener("click", () => { })
                add_Event_Mouse(item)
            })
        }
        else {
            AllActiveMobileMap.forEach((item) => {
                item.removeEventListener("mouseover", () => { })
                item.removeEventListener("mouseout", () => { })
                add_Event_Click(item);
            })
        }
        /* AllActiveMap.forEach((item) => {
             if (Max_Width.matches) {
                 Reset_Mobile_Config();
                 item.removeEventListener("click", () => { })
                 add_Event_Mouse(item);
             }
             else {
                 item.removeEventListener("mouseover", () => { })
                 item.removeEventListener("mouseout", () => { })
                 add_Event_Click(item);
             }
 
         });*/
    }



    function Open_Modal(modal) {
        modal.setAttribute('open', '')
    }

    function Remove_Modal(modal) {
        modal.removeAttribute('open');

        if (document.querySelector("[js-content-state].active"))
            document.querySelector("[js-content-state].active").classList.remove("active");
    }



    const MapSVG = find("[js-map='map'][desktop]");
    const MapSVG2 = find("[js-map='map'][mobile]");
    const ContainerMap = find("[js-map='container']");
    const AllActiveMap = document.querySelectorAll("[js-map='map'][desktop] .active");
    const AllActiveMobileMap = document.querySelectorAll("[js-map='map'][mobile] .active");
    const BTProjectByState = findAll("[js-bt-map]");

    const ModalMap = find(".Modal[Map]");

    let PageWidth = window.matchMedia("(min-width: 1024px)");
    add_Events(AllActiveMap, AllActiveMobileMap, PageWidth);

    if (BTProjectByState) {
        BTProjectByState.forEach((item) => {
            item.addEventListener("click", () => {
                const value_item = item.attributes['js-bt-map'].value;
                if (value_item === 'open') {
                    Open_Modal(ModalMap);
                }
                else {
                    Remove_Modal(ModalMap);
                }
            })
        })
        /*BTProjectByState.addEventListener("click", () => {
            Open_Modal(ModalMap);
        });*/
    }

    window.addEventListener("resize", () => {
        add_Events(AllActiveMap, AllActiveMobileMap, PageWidth);
    })

    window.addEventListener("scroll", () => {
        if (document.querySelector("[js-content-state].active"))
            document.querySelector("[js-content-state].active").classList.remove("active");
    })

}

export default Home_Map;