import Swiper from "swiper";
import { find, width } from "../utils/dom";

const Home_Team = () => {

    let SwiperTeam = Swiper;
    let Init_Swiper = false;
    /*let WidthDesktopFull = window.matchMedia('(min-width: 1720px)');

    const paddingInicial = 6.313;
    const ValueUnidadePX = 0.063;

    const ContainerSwiperTeam = find("[js-debug]");*/


    const ModalTeam = () => {
        const Modal = document.querySelector("[dialog='team']");

        const ButtonOpen = document.querySelectorAll("[js-button_open='team']");
        if (ButtonOpen) {
            ButtonOpen.forEach((item) => {
                item.addEventListener("click", (e) => {
                    const idDialog = e.target.attributes['dialog-id'].value;
                    const ModalTarget = document.querySelector(`[id-dialog='${idDialog}']`);
                    ModalTarget.show();
                });
            })
        }

        const ButtonClosed = document.querySelectorAll("[js-button_closed='team']");
        if (ButtonClosed) {
            ButtonClosed.forEach((item) => {
                item.addEventListener("click", (e) => {
                    const ModalActive = document.querySelector("[dialog='team'][open]");
                    ModalActive.close();
                })
            })
        }
    }

    const SwiperMode = () => {

        const mobile = window.matchMedia('(min-width: 0px) and (max-width: 767px)');
        const desktop = window.matchMedia('(min-width: 768px)');

        if (mobile.matches) {
            if (Init_Swiper) {
                SwiperTeam.destroy();
                Init_Swiper = false;
            }
        }
        else if (desktop.matches) {

            if (!Init_Swiper) {
                Init_Swiper = true;

                SwiperTeam = new Swiper(".swiper-team", {
                    slidesPerView: 2.2,
                    spaceBetween: 25,
                    mousewheel: {
                        forceToAxis: true,
                    },
                    pagination: {
                        el: ".swiper-team .swiper-pagination",
                        type: "progressbar",
                        clickable: true,
                    },
                    navigation: {
                        nextEl: ".swiper-team .swiper-button-next",
                        prevEl: ".swiper-team .swiper-button-prev",
                    },
                    breakpoints: {
                        640: {
                            slidesPerView: 3.5,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 4.3,
                        },

                        1600: {
                            slidesPerView: 4.8
                        },
                    },
                });
            }
        }
    }


    /*  const debugPaddingModal = () => {
          if (!WidthDesktopFull.matches) return;
  
          const LarguraJanela = window.screen.width;
  
          if (LarguraJanela >= 1720) {
              let contPadding = ValueUnidadePX * (LarguraJanela - 1720);
              ContainerSwiperTeam.style.paddingLeft = `${paddingInicial + contPadding}rem`;
          }
  
  
      }
  */

    window.addEventListener('load', () => {
        SwiperMode();
        ModalTeam();
        //debugPaddingModal();
    });

    window.addEventListener("resize", () => {
        SwiperMode();
        //debugPaddingModal();
    })

}

export default Home_Team;