import { find, findAll } from "../utils/dom";

const Copiar_Colar = () => {
    const Desktop = findAll("[js-component-desktop]");
    // const Mobile = findAll("[js-component-mobile]");

    if (!Desktop) return

    Desktop.forEach((item) => {
        const IdMobile = item.getAttribute('js-component-desktop');
        const Mobile = find(`[js-component-mobile='${IdMobile}']`);

        if (!Mobile) return;

        Mobile.innerHTML = item.innerHTML;
    })

}

export default Copiar_Colar;