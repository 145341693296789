import { find } from "../utils/dom";
import Swiper from "swiper";

const Home_Our_Partners = () => {
    const Swiper_Partners = new Swiper(".swiper-partners", {
        slidesPerView: 6,

    })

    const CondicaoLargura = window.matchMedia("(max-width: 767px)");


    window.addEventListener("resize", () => {
        if (CondicaoLargura.matches) {
            Swiper_Partners.destroy();
        }
    })
    document.addEventListener("DOMContentLoaded", () => {
        if (CondicaoLargura.matches) {
            Swiper_Partners.destroy();
        }
    })
}

export default Home_Our_Partners;