import { find } from "utils/dom"
import animateFrom from "../animation/animateFrom";

import Home_Map from "../sections/home_map";
import Company from "../sections/home_company";
import Home_Projects from "../sections/home_projects";
import Home_Team from "../sections/home_team";
import Home_What_Makes_Us_Unique from "../sections/home_what_makes_us_unique";
import Home_Header from "../sections/home_header";
import home_plataforms from "../sections/home_plataforms";
import Home_Our_Partners from "../sections/home_our_partners";

export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return


	document.addEventListener("DOMContentLoaded", () => {
		animateFrom(find("[js-anima]"))
	})


	home_plataforms()

	Home_Header();

	Home_Map();

	/* Seção - Company */
	Company();

	/* seção - what_makes_us_unique */
	Home_What_Makes_Us_Unique();

	/** Seção - Projects */
	Home_Projects();

	/** Seção - Team */
	Home_Team();

	/** Seção - Our Partners */
	Home_Our_Partners();
}